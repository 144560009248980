'use client' // Error boundaries must be Client Components

import { useEffect } from 'react'
import { Container, Link, Typography } from '@shc/ui'

export default function ErrorBoundary({
  error,
}: Readonly<{
  error: Error & { digest?: string }
}>) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error)
  }, [error])

  return (
    <Container className="grid grid-cols-12">
      <div className="col-span-12 lg:col-span-10 lg:col-start-2 flex flex-col justify-center my-10 md:my-15 gap-y-5">
        <Typography variant="h2" as="h1">
          Page not available
        </Typography>
        <Typography>
          We&apos;re sorry. An error has occurred on the page you were attempting to view. The
          following information has been reported and we will address this as soon as possible.
        </Typography>
        <Typography>
          If you need assistance, call us at &nbsp;
          <Link href="tel:+18008274277">1-800-82-SHARP</Link>
          &nbsp;(<Link href="tel:+18008274277">1-800-827-4277</Link>), &nbsp;Monday through Friday,
          7 am to 7 pm. If you&apos;re experiencing a medical or psychiatric emergency, call 911
          immediately or go to the nearest emergency room.
        </Typography>
      </div>
    </Container>
  )
}
